import neoAnalyse from "@shared/components/analyse";
import neoSwitch from "@shared/components/toggle-switch";
import { get } from "lodash";
import { mapGetters } from "vuex";
import axios from "@/axios";
import adverseMixin from "@shared/tools/mixins/adverseMixin";
import { EventBus } from "@/main.js";
import { getBackgroundStatus } from "@shared/utils/monitoringTools.js";
import { DateTime } from "luxon";
import Loader from "@shared/components/loader";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/potentialQuestionMark.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
import ConfirmDropdown from "@shared/components/confirm-dropdown/index.vue";
import CardDetailsButton from "@shared/saas-components/detail-button";

import { checkPermission } from "@shared/utils/functions";
import toolsHelper from "@shared/tools/mixins/toolsHelper";

export default {
    name: "adverse-media-card",
    components: {
        confirmed: Check,
        unknown: MinusIcon,
        potential: Question,
        irrelevant: CrossRed,
        neoAnalyse,
        neoSwitch,
        "neo-loader": Loader,
        CanvasMenu,
        ConfirmDropdown,
        CardDetailsButton,
    },
    mixins: [adverseMixin, toolsHelper],

    inject: {
        isCaseCreated: { default: false }
    },
    props: {
        riskCategoriesOptions: Array,
        mainEntity: Array,
        result: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Number,
            default: null,
        },
        index: {
            type: Number,
            default: null,
        },
        parentIndex: {
            type: Number,
            default: null,
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
        // getMonitoring: {
        //     type: Boolean,
        //     default: false,
        // },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        showConfirmation: {
            type: Boolean,
            default: false,
        },
        autorun: {
            type: Object,
            default: null,
        },
        checkId: {
            type: String,
            default: "",
        },
        entityId: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedQuery: {
            type: Object,
        },
    },
    data() {
        return {
            showList: false,
            individualData: {},
            openDetails: false,
            // resultSave: false,
            monitoringHistoryIndex: null,
            allCardDetails: {},
        };
    },
    methods: {
        // getMonitoring,
        checkPermission,
        getComponent(status) {
            return status;
        },

        // TODO rework logic if need to translate
        getCardStatusText(status) {
            return (status === 'IRRELEVANT' ? 'FALSE MATCHES' :  status === 'UNKNOWN' ? 'UNRESOLVED' : status === "None" ? "UNRESOLVED": status )
        },


        getStatusButtonClasses(status) {
            switch(status) {
                case 'confirmed': {
                    return "text-lime-600 border-lime-600";
                }
                case 'potential': {
                    return "text-custom-orange border-custom-orange";
                }
                case 'irrelevant': {
                    return "text-custom-red border-custom-red";
                }
                case 'unknown': {
                    return "text-gray-400 border-gray-400";
                }
                case 'none': {
                    return "text-gray-400 border-gray-400";
                }
                default : {
                    return  "text-primary border-primary-focus"
                }
            }
        },
        getHighlightShadow(status) {
            switch(status) {
                case 'confirmed': {
                    return "box-shadow: 0 4px 6px rgba(132, 204, 22, 0.6)"
                }
                case 'potential': {
                    return "box-shadow: 0 4px 6px rgba(252, 103, 19, 0.6)"
                }
                case 'irrelevant': {
                    return "box-shadow: 0 4px 6px rgba(235, 49, 49, 0.6)"
                }
                default : {
                    return  "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)"
                }
            }
        },

        getBorderColorClass(status) {

            switch(status) {
                case 'confirmed': {
                    return "border-lime-600";
                }
                case 'potential': {
                    return "border-custom-orange";
                }
                case 'irrelevant': {
                    return "border-custom-red";
                }
                case 'unknown': {
                    return "border-gray-400";
                }
                case 'none': {
                    return "border-gray-400";
                }
                default : {
                    return  "border-gray-100"
                }
            }
        },

        
        getStatusClass(status) {
            let statusClass = "rounded-full cursor-pointer p-1.5 h-7 w-7"
            if (status == 'potential' || status == 'irrelevant') {
                statusClass = "bg-red-100 " + statusClass
            }
            else if (status == 'unknown' || status == 'None') {
                statusClass = "bg-gray-300 " + statusClass
            }
            else {
                statusClass = "bg-green-50 " + statusClass
            }
            return statusClass
        },
        toogleDropdown() {
            if (!checkPermission("checkOutput.edit_card")) return;
            this.showList = !this.showList;
        },
        // checkCategories(item, result) {
        //     let risk = this.riskCategoriesOptions.find(v => v.category == item);
        //     if (risk) {
        //         return risk.category_type === 'Risk Categories' ? 
        //             ((result.entity_status === 'Amber') ? 'amberLabel' : 
        //                 'redLabel') : 
        //             'greenLabel';
        //     }
        // },
        closeDropdown() {
            this.showList = false;
        },
        async selectDropdown(event) {
            this.showList = false;
            let key = event?.target?.innerText?.toUpperCase() || event?.toUpperCase();
            if(key === "FALSE MATCHES") { 
                key = "IRRELEVANT";
            } else if(key === "UNRESOLVED") { 
                key = "UNKNOWN"
            }
            if (this.result.card_status === key) return;
            if (key === 'UNKNOWN') {
                key = "None"
            }
      
            let url;
            let payload = {
                case_id: this.getNeoCaseId,
                card_id: this.result.api_all_data.doc_id,
                entity: this.result.name,
                query_id: this.result.api_all_data.query_id,
                case_check_id: this.$route.query.check_id,
                tool_name: this.$route?.query?.check_name || this.autorun?.headers['x-tool-name'],
                status: key,
                main_entity_id: this.$route.query.entityId || this.entityId,
                card_type: this.result.entity_type=='Individual' ? "person": "company",
                source_name: "lexis",
            }
            url = `/service/upsert-card-result`;
            await axios.post(url, payload);  
            this.$toast.success("Successfully Updated");
            this.result.card_status = key
            this.updateCardStatus();
            
        },
        get,
        parseNames(data) {
            return data.map((e) => e.name).join(", ");
        },
        parseTypes(data) {
            if (data?.trim()) return data?.split(",")?.map((e) => e.replace(/-/g, " ")?.replace(/None/g, "Others"));
            else return [];
        },
        parseDate(dt, format = "EEEE, dd LLLL yyyy") {
            if (dt) return DateTime.fromISO(dt).toFormat(format);
            else return "";
        },
        // expandResult(result, curr_index, index) {
        //     this.$emit("expandResult", result, curr_index, index);
        // },
        fetchSource(key) {
            if (key === "dowJones") {
                return this.result.api_all_data?.sources?.map((src) => `DJ-${src}`.toUpperCase());
            } else return [key];
        },
        async handleMonitoring() {
            // event.stopPropagation();
            let card = this.result;
            card.api_all_data.monitoring_status = !card.api_all_data.monitoring_status;
            try {
                await axios.post("/service/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.getNeoCaseId,
                    query: this.getSelectedQuery(card.api_all_data.query_id),
                    tool: this.$route.query.check_name || this.autorun?.headers['x-tool-name'],
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: this.result.api_all_data.doc_id,
                    card_source: "lexis",
                    status: card.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success(this.$t('components.saas.monitoring_update_success'));
            } catch (error) {
                this.$toast.error(this.$t("general.default_error"));
            }
            // EventBus.$emit("handleDone", {card: this.result, curr_index: card.curr_index, oldVersion});
            this.$forceUpdate();
        },


        getSelectedQuery(query_id) {
            var findData = this.searchedQueries.find(x => x._id === query_id);
            if (!findData) return null;
            let maskedQuery = this.viewQuery(findData)
            findData.query_payload = {
                query_id: findData?._id,
                query_string: maskedQuery,
                search_string: maskedQuery,
            };
            return findData.query_payload;
        },

        getResultsID(index) {
            return `result-${index}`;
        },
        // changedResult(og_value, key, classes = false) {
        //     let result = {};
        //     let changeKey = this.get(this.result.api_all_data.change_history, key, []);
        //     if (changeKey.length) {
        //         result = this.getMonitoring(changeKey, og_value);
        //         return result;
        //     } else return { text: og_value };
        // },
        async handleDone({target}) {
            this.result.api_all_data.changes_viewed = !this.result.api_all_data.changes_viewed;
            // EventBus.$emit("handleDone", {card: this.result, curr_index: this.result.curr_index, oldVersion});

            let payload = [
                {
                    doc_id: this.result.api_all_data.doc_id,
                    sources: ["lexis"],
                    cascaded: false,
                },
            ];
            try {
            await axios.post("/service/mark-monitered-data-visited", {
                card_info: payload,
                case_id: this.getNeoCaseId,
                visited: target.checked,
            });
            }
            catch (err) {
                console.log(err)
            }

            this.updateComparisonReferences(this.result);
            setTimeout(()=>{
                this.$emit('getQueriesMonitoring')
                this.$toast.success(this.$t('components.saas.adverse_media.card_acknowledge_success'))
            }, 1000)
            
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        closeCanvas() {
            this.openDetails = false
        },
        updateCardStatus() {
            this.$emit("handleStatusUpdate")
        },
        showCardDetails(e) {
            e.stopPropagation()
            if (this.disabled) return;

            this.openDetails = true
            this.allCardDetails = {
                entity_identifier: this.result.entity_type=='Individual' ? "name" : "company",
                case_id: this.getNeoCaseId,
                card_id: this.result.api_all_data.doc_id,
                entity: this.result.name,
                query_id: this.result.api_all_data.query_id,
                tool_name: this.$route?.query?.check_name || this.autorun?.headers['x-tool-name'],
                status: this.result.card_status,
                main_entity_id: this.$route.query.entityId,
                card_type: this.result.entity_type=='Individual' ? "person": "company",
                relations: [],
                source_name: "lexis",
                notes: this.result.research_notes,
                customer_notes: this.result.customer_notes,
                risk_categories: this.result.risk_categories,
                bespoke: this.result.bespoke,
                entity_status: this.result.entity_status,
                screenshot: this.result.screenshot
            }
            // if (!this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex != this.result.api_all_data.doc_id) EventBus.$emit("handleResultSave", this.result);
        },
        getBackgroundStatus,

        showMonitoringHistory(index) {
            if (index !== this.monitoringHistoryIndex) this.monitoringHistoryIndex = index;
            else this.monitoringHistoryIndex = null;
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },
        getEntityType(result) {
            const type = result.entity_type;
            const key = result.key;
            if (key === "lexisBridger") {
                if (type === "Individual") return type;
                else return "Business";
            } else {
                return type;
                // if (type === "Individual" || ) return "Person";
                // else return "Company";
            }
        },
        getEntitySource(result) {
            if (result.entity_type.toLowerCase() === "person" || result.entity_type.toLowerCase() === "individual" || result.entity_type.toLowerCase() === "officer") return "name";
            else if (result.api_all_data.e_i && (result.api_all_data.e_i.toLowerCase() === "male" || result.api_all_data.e_i.toLowerCase() === "female")) return "name";
            else return "company_name";
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getCaseId", "getNeoCaseId", "getSelectedToolQuery", "searchedQueries", "getUserFeatures"]),
        isSelected() {
            return this.selected === this.result.curr_index;
        },
        monitoringAllowed() {
            return this.getUserFeatures.includes("adverse_media_pep_sanctions__monitoring")
        },
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },

        getCheckId() {
            return this.$route?.query?.check || this.checkId;
        },

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        getResultClasses() {
            let classes = "";
            if (this.result.curr_index === this.selected && this.isOldVersion) classes += " bg-blue-50 border-blue-600 hover:border-blue-200 shadow-md ";
            else classes += " shadow bg-white";
            if (this.result?.api_all_data?.hasChanges) classes += "border-green-500 border-3 shadow bg-white";
            else classes += " border ";
            // if (!this.result?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' ml-2';
            if (this.isMonitoringTab) {
                classes += "py-8";
            }
            return classes;
        },
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.result.api_all_data.doc_id) classes += " opacity-40 cursor-not-allowed ";
            if (this.result.api_all_data.saved) classes += " text-blue-700 font-semibold  text-xs bg-blue-200 ";
            else classes += " bg-blue-200 text-blue-800 ";
            return classes;
        },

        confirmStateModel: {
            get() {
                return this.result.card_status;
            },
            set(val) {
                this.selectDropdown(val);
                this.$emit("update:confirmation", val);
            },
        },

        queryId() {
            return this.result?.api_all_data?.query_id ?? "";
        },
    },
};
